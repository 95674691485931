* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  background: linear-gradient(45deg, #4158d0, #c850c0);
  min-height: 100vh;
  overflow-x: hidden;
}
