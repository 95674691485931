.timeline {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &__line {
    position: absolute;
    left: 46px;
    height: 100%;
    width: 3px;
    background-color: #fff;
    z-index: 1;

    &::after {
      content: " ";
      position: absolute;
      display: block;
      width: 30px;
      height: 3px;
      background-color: inherit;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%);
    }
  }

  &__year {
    width: 90px;
    text-align: center;
    color: #fff;
    background-color: #36304a;
    font-size: 16px;
    z-index: 2;
    position: relative;
    padding: 8px 13px;
    margin: 10px;
    margin-left: 10px;
    border-radius: 5px;
    margin-left: 0;
  }

  &__item {
    width: calc(100% - 100px);
    border: 1px solid #fff;
    background-color: #fff;
    padding: 20px;
    position: relative;
    margin: 20px 0px 20px 100px;
    z-index: 10;
    border-radius: 10px;
    transition: 500ms ease-out;
    left: 40px;
    opacity: 0;

    @media (max-width: 600px) {
      width: calc(100% - 20px);
      margin-left: 20px;
    }

    &--active {
      left: 0;
      opacity: 1;
    }

    &:last-of-type {
      margin-bottom: 40px;
    }

    &::after,
    &::before {
      content: " ";
      position: absolute;
      display: block;
      opacity: 1;
      transition: opacity 500ms ease-out;

      @media (max-width: 600px) {
        opacity: 0;
      }
    }

    &::before {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      left: -64px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      width: 60px;
      height: 2px;
      background-color: #fff;
      top: 50%;
      transform: translateY(-50%);
      left: -60px;
    }
  }
}
