.showcases {
  display: flex;
  width: calc(100% + 40px);
  position: relative;
  right: 20px;
  flex-wrap: wrap;
}

.showcase {
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  position: relative;
  width: calc(50% - 40px);
  margin: 20px;
  min-height: 270px;
  overflow: hidden;

  @media(max-width: 880px){
    width: 100%;
  }

  &:hover,
  &--open {
    .showcase__overlay {
      top: 0;
      opacity: 1;
    }
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center;
  }

  &__overlay {
    opacity: 0;
    position: absolute;
    top: 100%;
    height: 100%;
    left: 0;
    background-color: #fff;
    transition: 400ms ease-out;
    padding: 10px;
  }

  &__link {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #36304a;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
  }
}
