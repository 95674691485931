body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

.heading {
  color: #fff;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 200;
  font-size: 49px;
  margin: 0 0 22px 0;

  @media (max-width: 530px) {
    font-size: 40px;
  }

  &--line {
    &::after {
      display: block;
      position: static;
      content: " ";
      height: 1px;
      width: 100px;
      background-color: rgba(255, 255, 255, 0.7);
    }
  }

  &--secondary {
    font-size: 40px;
    margin-top: 48px;
    @media (max-width: 530px) {
      font-size: 31px;
    }
  }

  &--tertiary {
    font-size: 31px;
    margin-top: 48px;
    @media (max-width: 530px) {
      font-size: 25px;
    }
  }
}
