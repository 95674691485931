.footer{
  background-color: #36304a;

  &__inner{
    margin: 0 auto;
    padding: 20px;
    max-width: 920px;
    display: flex;
    justify-content: space-between;
  }

  &__item{
    color: #fff;
    text-decoration: none;
  }
}