.skill {
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  width: calc(100% / 5 - 20px);
  margin: 10px;
  position: relative;
  margin-top: 30px;
  padding-top: 20px;

  @media (max-width: 760px) {
    width: calc(100% / 4 - 20px);
  }

  @media (max-width: 570px) {
    width: calc(100% / 3 - 20px);
  }

  @media (max-width: 480px) {
    width: calc(100% / 2 - 20px);
  }

  @media (max-width: 340px) {
    width: 100%;
  }

  &__title {
    position: absolute;
    top: -25px;
    padding: 5px 10px;
    background-color: #36304a;
    color: #fff;
    font-weight: lighter;
    border-radius: 10px;
    left: 5px;

    @media (max-width: 570px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
