.circle-bar {
  position: relative;
  &:hover &__path{
    stroke-width: 3;
  }

  &:hover &__image{
    transform: translate(-50%, -50%) scale(1.1);
  }

  &__svg {
    display: block;
  }

  &__path {
    fill: none;
    stroke-width: 0;
    stroke-linecap: round;
    transition: 200ms ease-out;

    &--perfect {
      stroke: #05550b;
    }

    &--good {
      stroke: #12a51e;
    }

    &--average {
      stroke: #d1ce13;
    }

    &--bad {
      stroke: #d15f13;
    }

    &--active{
      stroke-width: 2;
      animation: progress 1s ease-out forwards;
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__image{
    max-width: 60px;
    max-height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 200ms ease-out;

    &--border{
      border: 1px solid #888;
    }
  }
}
