.table {
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;

  &__thead &__tr {
    height: 60px;
    background: #36304a;
  }

  &__thead &__th {
    text-align: left;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset;
  }

  &__tr {
    color: #6f6f6f;
    &:hover {
      color: #333333;
    }
  }

  &__tr:nth-child(odd) &__td {
    background-color: #f5f5f5;
  }

  &__th {
    font-size: 18px;
    color: #fff;
    padding: 10px;
  }

  &__td {
    padding: 10px;
    vertical-align: top;
  }

  &__tr &__td,
  &__tr &__th {

    &:first-of-type {
      padding-left: 40px;
    }

    &:last-of-type {
      padding-right: 40;
    }
  }
}
