@-webkit-keyframes progress {
  0% {
    stroke-dasharray: 0 100; } }

@keyframes progress {
  0% {
    stroke-dasharray: 0 100; } }

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: inherit; }

body {
  box-sizing: border-box;
  background: linear-gradient(45deg, #4158d0, #c850c0);
  min-height: 100vh;
  overflow-x: hidden; }

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px; }

.heading {
  color: #fff;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 200;
  font-size: 49px;
  margin: 0 0 22px 0; }
  @media (max-width: 530px) {
    .heading {
      font-size: 40px; } }
  .heading--line::after {
    display: block;
    position: static;
    content: " ";
    height: 1px;
    width: 100px;
    background-color: rgba(255, 255, 255, 0.7); }
  .heading--secondary {
    font-size: 40px;
    margin-top: 48px; }
    @media (max-width: 530px) {
      .heading--secondary {
        font-size: 31px; } }
  .heading--tertiary {
    font-size: 31px;
    margin-top: 48px; }
    @media (max-width: 530px) {
      .heading--tertiary {
        font-size: 25px; } }

.circle-bar {
  position: relative; }
  .circle-bar:hover .circle-bar__path {
    stroke-width: 3; }
  .circle-bar:hover .circle-bar__image {
    transform: translate(-50%, -50%) scale(1.1); }
  .circle-bar__svg {
    display: block; }
  .circle-bar__path {
    fill: none;
    stroke-width: 0;
    stroke-linecap: round;
    transition: 200ms ease-out; }
    .circle-bar__path--perfect {
      stroke: #05550b; }
    .circle-bar__path--good {
      stroke: #12a51e; }
    .circle-bar__path--average {
      stroke: #d1ce13; }
    .circle-bar__path--bad {
      stroke: #d15f13; }
    .circle-bar__path--active {
      stroke-width: 2;
      -webkit-animation: progress 1s ease-out forwards;
              animation: progress 1s ease-out forwards; }
  .circle-bar__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .circle-bar__image {
    max-width: 60px;
    max-height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 200ms ease-out; }
    .circle-bar__image--border {
      border: 1px solid #888; }

.showcases {
  display: flex;
  width: calc(100% + 40px);
  position: relative;
  right: 20px;
  flex-wrap: wrap; }

.showcase {
  border-radius: 10px;
  padding: 10px;
  background-color: #fff;
  position: relative;
  width: calc(50% - 40px);
  margin: 20px;
  min-height: 270px;
  overflow: hidden; }
  @media (max-width: 880px) {
    .showcase {
      width: 100%; } }
  .showcase:hover .showcase__overlay, .showcase--open .showcase__overlay {
    top: 0;
    opacity: 1; }
  .showcase__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center; }
  .showcase__overlay {
    opacity: 0;
    position: absolute;
    top: 100%;
    height: 100%;
    left: 0;
    background-color: #fff;
    transition: 400ms ease-out;
    padding: 10px; }
  .showcase__link {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #36304a;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none; }

.skill {
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  width: calc(100% / 5 - 20px);
  margin: 10px;
  position: relative;
  margin-top: 30px;
  padding-top: 20px; }
  @media (max-width: 760px) {
    .skill {
      width: calc(100% / 4 - 20px); } }
  @media (max-width: 570px) {
    .skill {
      width: calc(100% / 3 - 20px); } }
  @media (max-width: 480px) {
    .skill {
      width: calc(100% / 2 - 20px); } }
  @media (max-width: 340px) {
    .skill {
      width: 100%; } }
  .skill__title {
    position: absolute;
    top: -25px;
    padding: 5px 10px;
    background-color: #36304a;
    color: #fff;
    font-weight: lighter;
    border-radius: 10px;
    left: 5px; }
    @media (max-width: 570px) {
      .skill__title {
        left: 50%;
        transform: translateX(-50%); } }

.table {
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%; }
  .table__thead .table__tr {
    height: 60px;
    background: #36304a; }
  .table__thead .table__th {
    text-align: left;
    font-size: 18px;
    color: #fff;
    line-height: 1.2;
    font-weight: unset; }
  .table__tr {
    color: #6f6f6f; }
    .table__tr:hover {
      color: #333333; }
  .table__tr:nth-child(odd) .table__td {
    background-color: #f5f5f5; }
  .table__th {
    font-size: 18px;
    color: #fff;
    padding: 10px; }
  .table__td {
    padding: 10px;
    vertical-align: top; }
  .table__tr .table__td:first-of-type,
  .table__tr .table__th:first-of-type {
    padding-left: 40px; }
  .table__tr .table__td:last-of-type,
  .table__tr .table__th:last-of-type {
    padding-right: 40; }

.timeline {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px; }
  .timeline__line {
    position: absolute;
    left: 46px;
    height: 100%;
    width: 3px;
    background-color: #fff;
    z-index: 1; }
    .timeline__line::after {
      content: " ";
      position: absolute;
      display: block;
      width: 30px;
      height: 3px;
      background-color: inherit;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%); }
  .timeline__year {
    width: 90px;
    text-align: center;
    color: #fff;
    background-color: #36304a;
    font-size: 16px;
    z-index: 2;
    position: relative;
    padding: 8px 13px;
    margin: 10px;
    margin-left: 10px;
    border-radius: 5px;
    margin-left: 0; }
  .timeline__item {
    width: calc(100% - 100px);
    border: 1px solid #fff;
    background-color: #fff;
    padding: 20px;
    position: relative;
    margin: 20px 0px 20px 100px;
    z-index: 10;
    border-radius: 10px;
    transition: 500ms ease-out;
    left: 40px;
    opacity: 0; }
    @media (max-width: 600px) {
      .timeline__item {
        width: calc(100% - 20px);
        margin-left: 20px; } }
    .timeline__item--active {
      left: 0;
      opacity: 1; }
    .timeline__item:last-of-type {
      margin-bottom: 40px; }
    .timeline__item::after, .timeline__item::before {
      content: " ";
      position: absolute;
      display: block;
      opacity: 1;
      transition: opacity 500ms ease-out; }
      @media (max-width: 600px) {
        .timeline__item::after, .timeline__item::before {
          opacity: 0; } }
    .timeline__item::before {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #fff;
      left: -64px;
      top: 50%;
      transform: translateY(-50%); }
    .timeline__item::after {
      width: 60px;
      height: 2px;
      background-color: #fff;
      top: 50%;
      transform: translateY(-50%);
      left: -60px; }

.footer {
  background-color: #36304a; }
  .footer__inner {
    margin: 0 auto;
    padding: 20px;
    max-width: 920px;
    display: flex;
    justify-content: space-between; }
  .footer__item {
    color: #fff;
    text-decoration: none; }

.main {
  max-width: 920px;
  margin: 0 auto;
  padding: 50px 20px 100px 20px; }

.skills {
  display: flex;
  position: relative;
  right: 10px;
  width: calc(100% + 20px);
  flex-wrap: wrap; }
